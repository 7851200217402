import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"
import styled from "styled-components"
import { Link } from "gatsby"

import { H1, H2, P, PSmall } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"

import Header from "../components/header"
import Hero from "../components/hero"

import imageChateauPetitLeez from "../images/chateau-petit-leez.png"
import imageDegustation from "../images/degustation-2024.jpg"

const Button = styled.a`
  margin: 20px 0;
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 4px;
`

const Page = () => (
  <div>
    <SEO title="Raineri" />
    <Header/>

    <H1 className="text-center">Dégustation Vin Printemps 2024</H1>
    {/* <H2 className="text-center">La primavera e l'estate</H2> */}

    <Section className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">

          <P className="text-center">
            Le samedi 1 et dimanche 2 juin 2024 de 13 à 18 heures au <a href="https://goo.gl/maps/wpKESfhMyEgKovtP6" target="_blank">Château de Petit Leez</a>.
          </P>

          <br/>

          <img src={imageDegustation} className="img-fluid rounded" />

          <br/>
          <br/>

          <P>À notre aimable clientèle,</P>
          <P>Nous avons le plaisir de vous inviter à notre dégustation vin.</P>
          <P>Celle-ci aura lieu au Château de Petit Leez.</P>
          <P>Adresse : <a href="https://goo.gl/maps/wpKESfhMyEgKovtP6" target="_blank">Rue de Petit-Leez 129, 5031 Gembloux</a></P>
          <P>Au plaisir de vous y retrouver,</P>
          <P>L'équipe Raineri Gembloux</P>

          {/* <P>Chers clients</P>
          <P>
            Nous avons le plaisir de vous inviter à notre dégustation de vins qui aura lieu
            le vendredi 4 Novembre et le samedi 5 Novembre de 13 heures à 18 heures.</P>
          <P>Rendez-vous à notre magasin RAINERI GEMBLOUX, <a href="https://goo.gl/maps/vnDiZukKB9FQgD2f7" target="_blank">Rue de la Sucrerie 4 A - 5030 Gembloux</a></P>
          <P>Nous espérons vous rencontrer nombreux et vous remercions de votre fidélité</P> */}

          <br/>

          <PSmall>Note : Seuls les vins seront présentés, il n'y aura pas de nourriture ni de spiritueux.</PSmall>

          <br/>
          <br/>
        </div>
      </div>
    </Section>

    <Footer/>
  </div>
)

export default Page
